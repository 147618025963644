import React, { useState } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Header1 from "../components/Header1";
import Header2 from "../components/Header2";
import Header3 from "../components/Header3";
import Header5 from "../components/Header5";
import Header6 from "../components/Header6";
import AboutUs from "../components/AboutUs";
import FeaturedPictures from "../components/FeaturedPictures";
import SectionLayout1 from "../components/SectionLayout1";
import SectionLayout2 from "../components/SectionLayout2";
import SectionLayout3 from "../components/SectionLayout3";
import SectionLayout4 from "../components/SectionLayout4";
import SectionLayout5 from "../components/SectionLayout5";
import SectionLayout6 from "../components/SectionLayout6";
import SectionLayout7 from "../components/SectionLayout7";
import SectionLayout8 from "../components/SectionLayout8";
import SectionLayout9 from "../components/SectionLayout9";
import SectionLayout10 from "../components/SectionLayout10";
import RouteMapContainer from "../components/RouteMapContainer";
import VolunteerForm from "../components/VolunteerForm";
import SponsoringPartners from "../components/SponsoringPartners";
import SponsorsEnquiryForm from "../components/SponsorsEnquiryForm";
import EntrantForm from "../components/EntrantForm";
import NewsArticles from "../components/NewsArticles";
import Map from "../components/Map";
import Teams from "../components/Teams";
import Cta1 from "../components/Cta1";
import Cta2 from "../components/Cta2";
import Pricing from "../components/Pricing";
import GetInTouchForm from "../components/GetInTouchForm";
import FeatureAlbums from "../components/FeatureAlbums";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const PageTemplate = ({
	data: { wpPage, site, allWpArticle, allWpSponser },
}) => {
	const siteUrl = site?.siteMetadata?.siteUrl;

	const componentMap = {
		Page_Pagefields_Components_HeroSection: (data) => (
			<Hero
				heading={data.heading}
				button1={data.button1}
				button2={data.button2}
				button3={data.button3}
				overlayColour={data?.overlayColour}
				backgroundColour={data?.backgroundColour}
				backgroundImage={
					data.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
				backgroundMobileImage={
					data.backgroundMobileImage?.localFile?.childImageSharp
						?.gatsbyImageData
				}
			/>
		),
		Page_Pagefields_Components_AboutUs: (data) => (
			<AboutUs
				heading={data?.heading}
				image1={data.image1?.localFile?.childImageSharp?.gatsbyImageData}
				image2={data.image2?.localFile?.childImageSharp?.gatsbyImageData}
				body={data?.body1}
				body2={data?.body2}
				backgroundColour={data?.backgroundColour}
			/>
		),
		Page_Pagefields_Components_FeaturedPictures: (data) => (
			<FeaturedPictures
				featureImages={data?.featureImages}
				button={data?.button}
				backgroundColour={data?.backgroundColour}
			/>
		),
		Page_Pagefields_Components_Layout03: (data) => (
			<SectionLayout3
				heading={data?.heading}
				subheading={data?.subheading}
				body={data?.body}
				backgroundColour={data?.backgroundColour}
				overlayColour={data?.overlayColour}
				images={data?.images}
				backgroundImage={
					data.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
				button={data?.button}
			/>
		),
		Page_Pagefields_Components_RouteMapContainer: (data) => (
			<RouteMapContainer
				image={data.image?.localFile?.childImageSharp?.gatsbyImageData}
			/>
		),
		Page_Pagefields_Components_Cta01: (data) => (
			<Cta1
				backgroundImage={
					data.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
				heading={data?.heading}
				overlayColour={data?.overlayColour}
				button={data?.button}
				body={data?.body}
			/>
		),
		Page_Pagefields_Components_Cta02: (data) => (
			<Cta2
				backgroundImage={
					data.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
				heading={data?.heading}
				overlayColour={data?.overlayColour}
				button={data?.button}
				button2={data?.button2}
				body={data?.body}
				showTopShape={data?.showTopShape}
				backgroundColour2={data?.backgroundColour2}
			/>
		),

		Page_Pagefields_Components_Layout01: (data) => (
			<SectionLayout1
				image={data.image?.localFile?.childImageSharp?.gatsbyImageData}
				heading={data?.heading}
				backgroundColour={data?.backgroundColour}
				button={data?.button}
				body={data?.body}
			/>
		),
		Page_Pagefields_Components_Layout02: (data) => (
			<SectionLayout2
				image={data.image?.localFile?.childImageSharp?.gatsbyImageData}
				heading={data?.heading}
				subheading={data?.subheading}
				backgroundColour={data?.backgroundColour}
				backgroundColour2={data?.backgroundColour2}
				button={data?.button}
				body={data?.body}
			/>
		),
		Page_Pagefields_Components_Layout09: (data) => (
			<SectionLayout9
				image={data.image?.localFile?.childImageSharp?.gatsbyImageData}
				heading={data?.heading}
				backgroundColour={data?.backgroundColour}
				body={data?.body}
			/>
		),
		Page_Pagefields_Components_Layout08: (data) => (
			<SectionLayout8
				image={data.image?.localFile?.childImageSharp?.gatsbyImageData}
				heading={data?.heading}
				backgroundColour={data?.backgroundColour}
				backgroundColour2={data?.backgroundColour2}
				body={data?.body}
			/>
		),
		Page_Pagefields_Components_Layout04: (data) => (
			<SectionLayout4
				backgroundImage={
					data.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
				heading={data?.heading}
				overlayColour={data?.overlayColour}
				body={data?.body}
			/>
		),
		Page_Pagefields_Components_Layout05: (data) => (
			<SectionLayout5
				column1={data?.column1}
				column2={data?.column2}
				backgroundColour={data?.backgroundColour}
				button={data?.button}
			/>
		),
		Page_Pagefields_Components_Layout06: (data) => (
			<SectionLayout6
				heading={data?.heading}
				body={data?.body}
				backgroundColour={data?.backgroundColour}
				backgroundColour2={data?.backgroundColour2}
				image={data.image?.localFile?.childImageSharp?.gatsbyImageData}
			/>
		),
		Page_Pagefields_Components_Layout07: (data) => (
			<SectionLayout7
				heading={data?.heading}
				body={data?.body}
				backgroundColour={data?.backgroundColour}
				backgroundColour2={data?.backgroundColour2}
				image={data.image?.localFile?.childImageSharp?.gatsbyImageData}
				button={data?.button}
			/>
		),
		Page_Pagefields_Components_VolunteersInformationForm: (data) => (
			<VolunteerForm
				backgroundColour={data.backgroundColour}
				heading={data.heading}
				body={data.body}
			/>
		),
		Page_Pagefields_Components_EntrantsConditionsOfEntryForm: (data) => (
			<EntrantForm
				backgroundColour={data.backgroundColour}
				heading={data.heading}
				body={data.body}
				moreInfoText={data?.moreInfoText}
			/>
		),
		Page_Pagefields_Components_Header01: (data) => (
			<Header1
				backgroundColour={data.backgroundColour}
				backgroundColour2={data.backgroundColour2}
				heading={data.heading}
				body={data.body}
			/>
		),
		Page_Pagefields_Components_Header05: (data) => (
			<Header5
				backgroundColour={data.backgroundColour}
				heading={data.heading}
				body={data.body}
				overlayColour={data?.overlayColour}
				backgroundImage={
					data.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
			/>
		),
		Page_Pagefields_Components_Header06: (data) => (
			<Header6
				backgroundColour={data.backgroundColour}
				heading={data.heading}
				body={data.body}
				image={data.image?.localFile?.childImageSharp?.gatsbyImageData}
			/>
		),
		Page_Pagefields_Components_Teams: (data) => (
			<Teams
				backgroundColour={data.backgroundColour}
				teamMembers={data.teamMembers}
			/>
		),
		Page_Pagefields_Components_Header02: (data) => (
			<Header2
				backgroundColour={data.backgroundColour}
				backgroundColour2={data.backgroundColour2}
				heading={data.heading}
				body={data.body}
				backgroundImage={
					data.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
				backgroundMobileImage={
					data.backgroundMobileImage?.localFile?.childImageSharp
						?.gatsbyImageData
				}
			/>
		),
		Page_Pagefields_Components_Header03: (data) => (
			<Header3
				backgroundColour={data.backgroundColour}
				featuredArticle={data.featuredArticle[0]}
			/>
		),
		Page_Pagefields_Components_NewsArticles: (data) => (
			<NewsArticles
				backgroundColour={data.backgroundColour}
				allArticles={allWpArticle?.nodes}
			/>
		),
		Page_Pagefields_Components_GetInTouchForm: (data) => (
			<GetInTouchForm
				backgroundColour={data.backgroundColour}
				heading={data?.heading}
				body={data?.body}
				column1={data?.column1}
				column2={data?.column2}
			/>
		),
		Page_Pagefields_Components_Pricing: (data) => (
			<Pricing
				cards={data?.cards}
				backgroundColour={data?.backgroundColour}
				backgroundColour2={data?.backgroundColour2}
			/>
		),
		Page_Pagefields_Components_Map: (data) => <Map mapLink={data.mapLink} />,
		Page_Pagefields_Components_SponsoringPartners: (data) => (
			<SponsoringPartners
				allWpSponser={allWpSponser.nodes}
				backgroundColour={data?.backgroundColour}
				backgroundColour2={data?.backgroundColour2}
				heading={data?.heading}
				body={data?.body}
				heading2={data?.heading2}
				body2={data?.body2}
				backgroundImage={
					data.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
				}
				overlayColour={data.overlayColour}
				backgroundMobileImage={
					data.backgroundMobileImage?.localFile?.childImageSharp
						?.gatsbyImageData
				}
			/>
		),
		Page_Pagefields_Components_SponsorEnquiryForm: (data) => (
			<SponsorsEnquiryForm
				heading={data?.heading}
				body={data?.body}
				backgroundColour={data?.backgroundColour}
			/>
		),
		Page_Pagefields_Components_FeaturedAlbums: (data) => (
			<FeatureAlbums
				heading={data?.heading}
				albumImages={data?.albumImages}
				videos={data?.videos}
				backgroundColour={data?.backgroundColour}
			/>
		),
		Page_Pagefields_Components_Layout10: (data) => (
			<SectionLayout10
				image={data.image?.localFile?.childImageSharp?.gatsbyImageData}
				heading={data?.heading}
				subheading={data?.subheading}
				backgroundColour={data?.backgroundColour}
				button={data?.button}
				body={data?.body}
			/>
		),
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	const { pageSettings } = wpPage.pageFields;

	return (
		<Layout pageSettings={pageSettings}>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={wpPage?.seoFields?.metaTitle || wpPage?.title}
				description={wpPage?.seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${wpPage.seoFields?.opengraphTitle || wpPage?.title}`,
					description: `${wpPage.seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${wpPage.seoFields?.image?.sourceUrl}`,
							width: `${wpPage.seoFields?.image?.mediaDetails.width}`,
							height: `${wpPage.seoFields?.image?.mediaDetails.height}`,
							alt: `${wpPage.seoFields?.image?.altText}`,
						},
					],
				}}
			/>
			{wpPage.pageFields.components.map((component, index) => {
				const renderComponent = componentMap[component.fieldGroupName];
				return renderComponent ? (
					<div key={index}>{renderComponent(component)}</div>
				) : null;
			})}
		</Layout>
	);
};

export default PageTemplate;

export const pageQuery = graphql`
	query HomeById {
		site {
			siteMetadata {
				siteUrl
			}
		}
		allWpPost(filter: { slug: { ne: "website-images" } }) {
			nodes {
				title
				slug
				uri
				excerpt
			}
		}
		allWpArticle(sort: { fields: date, order: DESC }) {
			nodes {
				articleFields {
					content
					fieldGroupName
					featureImage {
						altText
						sourceUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [WEBP, AUTO]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				title
				slug
				dateGmt(formatString: "MMMM DD, YYYY")
			}
		}
		allWpSponser {
			nodes {
				sponsorFields {
					description
					hasLink
					linkUrl
					logo {
						altText
						sourceUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [WEBP, AUTO]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
					section
				}
				title
			}
		}

		wpPage(slug: { eq: "homepage" }) {
			slug
			title
			seoFields {
				opengraphTitle
				opengraphDescription
				metaTitle
				metaDescription
				fieldGroupName
				productSchema
				image {
					altText
					sourceUrl
					mediaDetails {
						height
						width
					}
				}
			}
			pageFields {
				pageSettings {
					footerColor
					footerOverlayColor
					headerColor
					imagePosition
					showFooterAbstractImage
					showHeaderAbstractImage
					disableFooterShape
					footerBackgroundImage {
						altText
						sourceUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [WEBP, AUTO]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				components {
					... on WpPage_Pagefields_Components_HeroSection {
						backgroundColour
						fieldGroupName
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						backgroundMobileImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						button1 {
							target
							title
							url
						}
						button2 {
							target
							title
							url
						}
						button3 {
							target
							title
							url
						}
						heading
						overlayColour
					}
					... on WpPage_Pagefields_Components_AboutUs {
						backgroundColour
						body1
						body2
						fieldGroupName
						heading
						image1 {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						image2 {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_FeaturedPictures {
						backgroundColour
						fieldGroupName
						featureImages {
							image {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						button {
							target
							title
							url
						}
					}
					... on WpPage_Pagefields_Components_RouteMapContainer {
						fieldGroupName
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_Layout03 {
						backgroundColour
						body
						fieldGroupName
						heading
						overlayColour
						subheading
						images {
							fieldGroupName
							image1 {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							image2 {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							image3 {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							image4 {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							image5 {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							image6 {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						button {
							target
							title
							url
						}
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_Cta01 {
						body
						fieldGroupName
						heading
						overlayColour
						button {
							target
							title
							url
						}
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_Layout01 {
						backgroundColour
						body
						heading
						button {
							target
							title
							url
						}
						fieldGroupName
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_Layout02 {
						backgroundColour
						backgroundColour2
						body
						fieldGroupName
						heading
						subheading
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						button {
							target
							title
							url
						}
					}
					... on WpPage_Pagefields_Components_VolunteersInformationForm {
						backgroundColour
						body
						fieldGroupName
						heading
					}
					... on WpPage_Pagefields_Components_EntrantsConditionsOfEntryForm {
						backgroundColour
						body
						fieldGroupName
						heading
						moreInfoText
					}
					... on WpPage_Pagefields_Components_Header01 {
						backgroundColour
						backgroundColour2
						body
						fieldGroupName
						heading
					}
					... on WpPage_Pagefields_Components_Header05 {
						backgroundColour
						body
						fieldGroupName
						heading
						overlayColour
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_Teams {
						backgroundColour
						fieldGroupName
						teamMembers {
							... on WpTeamMember {
								id
								teamMemberFields {
									description
									name
									role
									image {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_Header03 {
						backgroundColour
						fieldGroupName
						featuredArticle {
							... on WpArticle {
								id
								articleFields {
									featureImage {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [WEBP, AUTO]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
									content
								}
								title
								dateGmt(formatString: "MMMM DD, YYYY")
								author {
									node {
										name
									}
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_NewsArticles {
						backgroundColour
						fieldGroupName
					}
					... on WpPage_Pagefields_Components_GetInTouchForm {
						backgroundColour
						body
						fieldGroupName
						heading
						column1 {
							body
							heading
							icon {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						column2 {
							body
							heading
							icon {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_Header01 {
						backgroundColour
						backgroundColour2
						body
						fieldGroupName
						heading
					}
					... on WpPage_Pagefields_Components_FeaturedAlbums {
						backgroundColour
						fieldGroupName
						heading
						videos {
							video {
								altText
								mediaItemUrl
							}
						}
						albumImages {
							fieldGroupName
							image {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_Layout10 {
						backgroundColour
						body
						fieldGroupName
						heading
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						subheading
					}
					... on WpPage_Pagefields_Components_Cta02 {
						backgroundColor2
						backgroundColour
						showTopShape
						fieldGroupName
						heading
						overlayColour
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						button {
							target
							title
							url
						}
					}
					... on WpPage_Pagefields_Components_Header02 {
						backgroundColour
						backgroundColour2
						body
						fieldGroupName
						heading
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						backgroundMobileImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_Layout09 {
						backgroundColour
						body
						fieldGroupName
						heading
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_Layout08 {
						backgroundColour
						backgroundColour2
						body
						fieldGroupName
						heading
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_Header06 {
						backgroundColour
						body
						fieldGroupName
						heading
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_Layout04 {
						body
						fieldGroupName
						heading
						overlayColour
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_Map {
						fieldGroupName
						mapLink
					}
					... on WpPage_Pagefields_Components_Layout05 {
						backgroundColour
						fieldGroupName
						column1 {
							backgroundImage {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							body
							fieldGroupName
							heading
							overlayColour
						}
						column2 {
							backgroundImage {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							body
							heading
							overlayColour
						}
						button {
							target
							title
							url
						}
					}
					... on WpPage_Pagefields_Components_Layout06 {
						backgroundColour
						backgroundColour2
						body
						fieldGroupName
						heading
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_Layout07 {
						backgroundColour
						backgroundColour2
						body
						fieldGroupName
						heading
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						button {
							target
							title
							url
						}
					}
					... on WpPage_Pagefields_Components_SponsoringPartners {
						backgroundColour
						backgroundColour2
						fieldGroupName
						body
						body2
						heading
						heading2
						overlayColour
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						backgroundMobileImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_Cta02 {
						backgroundColour
						fieldGroupName
						heading
						overlayColour
						button {
							target
							title
							url
						}
						button2 {
							target
							title
							url
						}
						backgroundImage {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [WEBP, AUTO]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_Pricing {
						backgroundColour
						backgroundColour2
						fieldGroupName
						cards {
							colour
							fieldGroupName
							heading
							icon {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							items {
								text
							}
							priceSvg {
								altText
								sourceUrl
								mediaItemUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_SponsorEnquiryForm {
						backgroundColour
						body
						fieldGroupName
						heading
					}
					... on WpPage_Pagefields_Components_FeaturedAlbums {
						backgroundColour
						fieldGroupName
						heading
						albumImages {
							image {
								altText
								sourceUrl
								mediaItemUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [WEBP, AUTO]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						videos {
							video {
								mediaItemUrl
							}
						}
					}
				}
			}
		}
	}
`;
